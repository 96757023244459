import { env } from "@/env.mjs"
import { isProductionEnvironment } from "@/libs/environment"
import { WebPlatform } from "@/libs/platforms/web-platform"
import type { CaseStudy } from "@/models/case-study"
import { toMuxVideoForDisplay } from "@/models/mux-video"
import type { MediaProviderAdapter } from "@vidstack/react"
import { MediaPlayer, MediaProvider, isHLSProvider, useMediaProvider } from "@vidstack/react"
import { DefaultVideoLayout, defaultLayoutIcons } from "@vidstack/react/player/layouts/default"
import Hls from "hls.js"
import mux from "mux-embed"
import { useEffect, type ReactNode } from "react"

function PlayerTracking({
  title,
  posterUrl,
  inline,
}: {
  title: string
  posterUrl: string
  inline: boolean
}) {
  const provider = useMediaProvider()
  useEffect(() => {
    if (isHLSProvider(provider)) {
      provider.onInstance(async (hls) => {
        const initTime = mux.utils.now()
        const platform = WebPlatform({ cookieDomain: window.location.hostname })
        const deviceId = await platform.getDeviceId()
        mux.monitor(provider.video, {
          debug: !isProductionEnvironment,
          hlsjs: hls,
          Hls,
          data: {
            env_key: env.NEXT_PUBLIC_MUX_DATA_ENV_KEY,
            player_name: inline ? "Inline Player" : "Modal Player",
            player_init_time: initTime,
            video_title: title,
            video_poster_url: posterUrl,
            viewer_user_id: `deviceId:${deviceId}`,
          },
        })
      })
    }
  }, [inline, posterUrl, provider, title])
  return null
}

// This is where you should configure providers.
function onProviderChange(provider: MediaProviderAdapter | null) {
  if (isHLSProvider(provider)) {
    provider.library = Hls
  }
}

export function Video(
  props: Readonly<Pick<CaseStudy, "id" | "title" | "description" | "primaryVideo">>
): ReactNode {
  const details = toMuxVideoForDisplay(props.primaryVideo)

  if (!details) {
    console.error({ props }, "Video Component missing `muxId` prop")
    return null
  }

  const inline = false // TODO: Add back support

  return (
    <div className="transition-all duration-[0.5s]">
      <div className="aspect-video">
        <MediaPlayer
          id={details.id}
          title={props.title}
          src={details.srcUrl}
          poster={details.posterUrl}
          aspectRatio="16/9"
          autoPlay={true}
          muted={inline}
          loop={inline}
          load={inline ? "visible" : "eager"}
          onProviderChange={onProviderChange}
        >
          <PlayerTracking title={props.title} posterUrl={details.posterUrl} inline={inline} />
          <MediaProvider>{/* <Poster alt={props.title} /> */}</MediaProvider>
          {!inline ? (
            <DefaultVideoLayout
              icons={defaultLayoutIcons}
              thumbnails={details.thumbnailsStoryboardUrl}
            />
          ) : null}
        </MediaPlayer>
      </div>
    </div>
  )
}
