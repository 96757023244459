import { UAParser } from "ua-parser-js"
import type { DeviceIDGenerator } from "../device-id"
import { ULIDDeviceIDGenerator } from "../device-id"
import type { BrowserInfo, DeviceInfo, OSInfo, Platform, ScreenInfo } from "./platform"

const DeviceIdPrefix = "w-"
const DeviceIdKey = "sb-device-id"

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface WebPlatform extends Platform {}

interface WebPlatformOptions {
  cookieDomain: string
  deviceIdGenerator?: DeviceIDGenerator
}

export function WebPlatform(opts: WebPlatformOptions): WebPlatform {
  const uaParser = new UAParser()
  const deviceIdGenerator = opts.deviceIdGenerator ?? ULIDDeviceIDGenerator()

  async function getStoredItemFromCookie(key: string): Promise<string | undefined> {
    if (document?.cookie) {
      const cookieItem = document.cookie.split(/;\s*/).find((item) => item.startsWith(`${key}=`))
      if (!cookieItem) {
        return undefined
      }
      const value = cookieItem.split(/=/, 2)[1]
      return value
    }

    return undefined
  }

  async function getStoredItemFromLocalStorage(key: string): Promise<string | undefined> {
    if (window?.localStorage?.getItem) {
      const val = window.localStorage.getItem(key)
      if (val === null) {
        return undefined
      }
      return val
    }

    return undefined
  }

  async function setStoredItemInLocalStorage(key: string, value: string): Promise<void> {
    if (window?.localStorage?.setItem) {
      window.localStorage.setItem(key, value)
    }
  }

  async function setStoredItemInCookies(
    key: string,
    value: string,
    domain: string,
    maxAgeSeconds: number,
    sameSite = "lax"
  ): Promise<void> {
    if (document) {
      const cookie = `${key}=${value}; Domain=${domain}; path='/'; Max-Age=${maxAgeSeconds}; SameSite=${sameSite}`
      document.cookie = cookie
    }
  }

  // memoize in this closure so that we don't have to get from storage every single time
  let deviceId: string | undefined = undefined
  async function getDeviceId(): Promise<string> {
    if (deviceId) {
      return deviceId
    }

    let deviceIdWasInCookies = false
    deviceId = await getStoredItemFromLocalStorage(DeviceIdKey)
    if (!deviceId) {
      deviceId = await getStoredItemFromCookie(DeviceIdKey)
      deviceIdWasInCookies = Boolean(deviceId)
    }

    let deviceIdWasGenerated = false
    if (!deviceId) {
      deviceId = deviceIdGenerator.generateDeviceId(DeviceIdPrefix)
      deviceIdWasGenerated = true
    }

    if (deviceIdWasGenerated || !deviceIdWasInCookies) {
      await setDeviceId(deviceId)
    }

    return deviceId
  }

  async function setDeviceId(deviceId: string): Promise<void> {
    try {
      await Promise.all([
        setStoredItemInLocalStorage(DeviceIdKey, deviceId),
        setStoredItemInCookies(
          DeviceIdKey,
          deviceId,
          opts.cookieDomain,
          Number.MAX_SAFE_INTEGER,
          "lax"
        ),
      ])
    } catch {
      // oh well, maybe no permissions, or exceeds quota, no need to
      // throw anything, keep using `deviceId` then
    }
  }

  async function screenInfo(): Promise<ScreenInfo> {
    return {
      width: window?.screen?.width,
      height: window?.screen?.height,
      orientation: window?.screen?.orientation?.type,
    }
  }

  async function osInfo(): Promise<OSInfo> {
    const os = uaParser.getOS()
    return {
      name: os.name ?? window?.navigator?.platform,
      version: window.navigator.platform,
    }
  }

  async function deviceInfo(): Promise<DeviceInfo> {
    return uaParser.getDevice()
  }

  async function browserInfo(): Promise<BrowserInfo> {
    const browser = uaParser.getBrowser()
    return {
      userAgent: window.navigator.userAgent,
      ...browser,
    }
  }

  async function fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    if (typeof window.fetch !== "function") {
      throw new Error("client has no `window.fetch`")
    }
    return window.fetch(input, init)
  }

  return {
    type: () => WebPlatform.name,
    getDeviceId,
    screenInfo,
    osInfo,
    deviceInfo,
    browserInfo,
    fetch,
  }
}
